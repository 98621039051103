#contact {
  .container {
    h1 {
      color: #15f178;
      font-weight: 800;
      font-size: 60px;
    }
    .col a {
      font-family: 'League Spartan', normal;
      text-decoration: none;
      margin: 0;
      font-weight: 800;
    }
  }
}