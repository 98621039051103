@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Montserrat&display=swap');

/* Reset setting */
* {
  padding: 0;
  margin: 0;
  font-weight: 500;
}
h1, h2, p, a {
  color: rgb(206, 206, 209);
}
.container-fluid {
  background-color: #1e1f20;
  height: 100%;
  overflow: hidden;
  position: relative;
}

section {
  display: flex;
  min-height: 100vh;
  height: 100%;
  overflow-y: hidden;
}
