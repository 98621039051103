#projects {
  .container {
    .card {
      border: none;
      background-color: transparent;
    }
    .card:hover {
      .card-img-overlay {
        display: flex;
      }
    }
    .card-img-overlay {
      background-color: rgba($color: #000000, $alpha: 0.8);
      display: none;
      flex-direction: column;
      justify-content: space-between;
    }
    .card-text {
      font-size: 18px;
      font-weight: 700;
      color: #15f178;
      &.title {
        font-size: 24px;
        text-align: center;
      }
      &.titleMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.description {
        font-size: 16px;
        font-weight: 500;
        color: rgb(206, 206, 209);
      }
      &.packages {
        display: flex;
        flex-wrap: wrap;
        span::before {
          content: "- ";
        }
        span {
          width: fit-content;
          margin-right: 10px;
          font-size: 16px;
          color: rgb(206, 206, 209);
          font-weight: 500;
        }
      }
      &.links, span.links{
        text-align: center;
        a {
          font-size: 30px;
          color: rgb(206, 206, 209);
        }
        span {
          margin: 0 10px;
        }
      }
    }
  }
}