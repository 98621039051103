.container {
  margin: auto;
  h1 {
    span {
      font-size: 70px;
    }
  }
  h2 {
    span {
      font-size: 55px;
    }
  }
  p, #learn {
    font-family: 'League Spartan', normal;
    font-size: 20px;
    margin: 0;
  }
  span {
    color: #15f178;
    font-size: 35px;
    font-weight: 800;
  }

  .col a {
    font-size: 2em;
    margin-right: 15px;
    color: rgb(206, 206, 209) !important;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    cursor: none;
    span {
      font-size: 18px;
      color: rgb(206, 206, 209);
    } 
  }

  a:hover, a:hover span {
    color: #15f178 !important;
  }

  // position relative to be unaffected by the cursor
  #learn {
    border: 3px solid #15f178;
    background-color: transparent;
    color: rgb(206, 206, 209);
    padding: 10px 60px;
    margin-top: 30px;
  }
  #learn:hover {
    background-color:#15f178;
    border: 3px solid #15f178;
    color: #1e1f20;
    outline: 30px solid rgba($color: #ffffff, $alpha: 0.1);
    transition: outline 1s;
    cursor: none;
  }
}